/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { Article } from './components/article-page'
import { Author } from './components/author-page'

type Metadata = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaults?: any
  isDev?: boolean
}

const metadata: Metadata = {}

const endpoint = 'api/counter5'

const _axios = process.env.BASE_URL
  ? axios.create({
    baseURL: process.env.BASE_URL,
  })
  : axios

/// /////////////////////////////////////////////////////////////////////////////
//  TYPES
/// /////////////////////////////////////////////////////////////////////////////

type Config = {
  isDev: boolean
}

type DBType = {
  dbid: string
  dbname: string
}

const PUBLICATION_DB: DBType = {
  dbid: 'PUBLICATION_DB',
  dbname: 'Publications',
}
const AUTHOR_DB: DBType = {
  dbid: 'AUTHOR_DB',
  dbname: 'Authors',
}
const JOURNAL_DB: DBType = {
  dbid: 'JOURNAL_DB',
  dbname: 'Journals',
}
const SERIES_DB: DBType = {
  dbid: 'SERIES_DB',
  dbname: 'Series',
}

type DefaultsParams = {
  aid?: string
  aname?: string
  cid?: string
  cname?: string
}

export type Serial = {
  id: string
  title: string
}

enum Method {
  setDefaults = 'setDefaults',
  trackSearch = 'trackSearch',
  trackRequest = 'trackRequest',
  trackInvestigation = 'trackInvestigation',
}

const methodsURL = {
  trackSearch: 'se',
  trackRequest: 'ir',
  trackInvestigation: 'ii',
}

/// /////////////////////////////////////////////////////////////////////////////
//  Remove undefined and null values from object
/// /////////////////////////////////////////////////////////////////////////////
function removeUndefinedNullEmpty(obj) {
  Object.keys(obj).forEach(k => {
    if (obj[k] && typeof obj[k] === 'object') {
      removeUndefinedNullEmpty(obj[k])
    } else if (obj[k] === undefined || obj[k] === null || (typeof obj[k] === 'string' && obj[k] === '')) {
      delete obj[k]
    }
  })

  return obj
}

/// /////////////////////////////////////////////////////////////////////////////
//  Get the method to call based on the article review
/// /////////////////////////////////////////////////////////////////////////////
function getMethod(article: Article) {
  return article.review && article.review.trim().length > 0
    ? Method.trackRequest
    : Method.trackInvestigation
}

/// /////////////////////////////////////////////////////////////////////////////
//
/// /////////////////////////////////////////////////////////////////////////////
function pushIntoArray(method: Method, data: any) {
  const args: [string, any] = [method, data]

  if (metadata.isDev) {
    console.info('COUNTER:', args)
  } else {
    _axios.post(`${endpoint}?m=${methodsURL[method]}`, {
      ...metadata.defaults,
      ...data,
      url: window.location.href,
      ua: window.navigator.userAgent,
    }, {
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
      },
      responseType: 'text',
    })

    window._ll.push(args)
  }
}

function unshiftIntoArray(method: Method, data: unknown) {
  const args: [string, any] = [method, data]

  if (metadata.isDev) {
    console.info('COUNTER:', args)
  } else {
    window._ll.unshift(args)
  }
}

/// /////////////////////////////////////////////////////////////////////////////
//
/// /////////////////////////////////////////////////////////////////////////////
function trackArticle(article: Article, imDType: 'Article' | 'Other', url: string | undefined) {
  const method = getMethod(article)

  const tm = article.issue.issue.journal && article.issue.issue.journal.title.trim().length > 0
    ? {
      tm: {
        id: article.issue.issue.journal.jourId ? `JOURNAL_${article.issue.issue.journal.jourId}` : null,
        title: article.issue.issue.journal.title,
        dtype: 'Journal',
        issnp: article.issue.issue.issn.toUpperCase(),
        issno: article.issue.issue.eIssn?.toUpperCase(),
      },
    }
    : {}

  const isbn13 = article.issue.issue?.isbn && article.issue.issue.isbn.length > 0
    ? article.issue.issue.isbn[0].replaceAll('-', '').length < 13 ? null : article.issue.issue.isbn[0].replaceAll('[', '').replaceAll(']', '')
    : null

  const data = removeUndefinedNullEmpty({
    ...PUBLICATION_DB,
    url,
    at: 'controlled',
    am: 'regular',
    ...tm,
    im: {
      id: `MR${article.paperId}`,
      title: article.titles.title,
      dtype: imDType,
      doi: article.details.doi && article.details.doi.trim().length > 0 ? article.details.doi : null,
      isbn: isbn13,
      yop: article.issue.issue.pubYearInt,
      pub: article.issue.issue.journal.publisher && article.issue.issue.journal.publisher.name.trim().length > 0 ? article.issue.issue.journal.publisher.name : null,
    },
  })

  pushIntoArray(method, data)
}

function trackArticleCollection(article: Article, url: string | undefined) {
  trackArticle(article, 'Other', url)
}

/// /////////////////////////////////////////////////////////////////////////////
//
/// /////////////////////////////////////////////////////////////////////////////

function trackBook(article: Article, imDType: 'Book' | 'Other', url: string | undefined) {
  const method = getMethod(article)

  const tm = article.book?.series && article.book?.series.length > 0 && article.book.series[0].title.trim().length > 0
    ? {
      tm: {
        id: article.book.series[0].serId ? `SERIES_${article.book.series[0].serId}` : null,
        title: article.book.series[0].title,
        dtype: 'Other',
      },
    }
    : {}

  const isbn13 = article.book?.isbn && article.book.isbn.length > 0
    ? article.book.isbn[0].replaceAll('-', '').length < 13 ? null : article.book.isbn[0].replaceAll('[', '').replaceAll(']', '')
    : null

  const data = removeUndefinedNullEmpty({
    ...PUBLICATION_DB,
    url,
    at: 'controlled',
    am: 'regular',
    ...tm,
    im: {
      id: `MR${article.paperId}`,
      title: article.titles.title,
      dtype: article.itemType.toLowerCase() === 'thesis' ? 'Thesis_or_Dissertation' : imDType,
      doi: article.details.doi && article.details.doi.trim().length > 0 ? article.details.doi : null,
      isbn: isbn13,
      yop: article.book?.pubYearInt,
      pub: article.book?.publisher && Array.isArray(article.book?.publisher) && article.book?.publisher.length > 0 ? article.book?.publisher[0].name : null,
    },
  })

  pushIntoArray(method, data)
}

function trackBookCollection(article: Article, url: string | undefined) {
  trackBook(article, 'Other', url)
}

/// /////////////////////////////////////////////////////////////////////////////
//
/// /////////////////////////////////////////////////////////////////////////////
function trackSearch(db: DBType) {
  const data = {
    // sa: true,
    dbs: [{ ...db }],
  }

  pushIntoArray(Method.trackSearch, data)
}

/// /////////////////////////////////////////////////////////////////////////////
//  API
/// /////////////////////////////////////////////////////////////////////////////

export function setConfigCounter5(config: Config): void {
  metadata.isDev = config.isDev
}

export function setDefaultsCounter5(params: DefaultsParams): void {
  const data = removeUndefinedNullEmpty({
    pid: 'e98bfcaf7e392286',
    plid: 'MathSciNet',
    plname: 'MathSciNet',
    ...params,
  })

  metadata.defaults = data

  unshiftIntoArray(Method.setDefaults, data)
}

export function trackArticleCounter5(article: Article | null, url?: string): void {
  if (!article) return

  if (['J', 'JT'].find(t => article.details.entryType === t)) {
    return trackArticle(article, 'Article', url)
  }

  if (['JC', 'JCZ'].find(t => article.details.entryType === t)) {
    return trackArticleCollection(article, url)
  }

  if (['B'].find(t => article.details.entryType === t)) {
    return trackBook(article, 'Book', url)
  }

  if (['BC', 'BCZ'].find(t => article.details.entryType === t)) {
    return trackBookCollection(article, url)
  }
}

export function trackAuthorCounter5(author: Author | null): void {
  if (!author) return

  const data = removeUndefinedNullEmpty({
    ...AUTHOR_DB,
    at: 'controlled',
    am: 'regular',
    im: {
      title: author.profileName,
      dtype: 'Other',
      id: author.id ? `AUTHOR_${author.id}` : undefined,
      pub: 'American Mathematical Society',
    },
  })

  pushIntoArray(Method.trackInvestigation, data)
}

export function trackJournalCounter5(journal: Serial | null): void {
  if (!journal) return

  const data = removeUndefinedNullEmpty({
    ...JOURNAL_DB,
    at: 'controlled',
    am: 'regular',
    im: {
      title: journal.title,
      dtype: 'Journal',
      id: journal.id ? `JOURNAL_${journal.id}` : undefined,
    },
  })

  pushIntoArray(Method.trackInvestigation, data)
}

export function trackSerieCounter5(series: Serial | null): void {
  if (!series) return

  const data = removeUndefinedNullEmpty({
    ...SERIES_DB,
    at: 'controlled',
    am: 'regular',
    im: {
      title: series.title,
      dtype: 'Other',
      id: series.id ? `SERIES_${series.id}` : undefined,
    },
  })

  pushIntoArray(Method.trackInvestigation, data)
}

export function trackPublicationsSearchCounter5(): void {
  trackSearch(PUBLICATION_DB)
}

export function trackAuthorsSearchCounter5(): void {
  trackSearch(AUTHOR_DB)
}

export function trackJournalsSearchCounter5(): void {
  trackSearch(JOURNAL_DB)
}

export function trackSeriesSearchCounter5(): void {
  trackSearch(SERIES_DB)
}
